// SearchCommune.jsx

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComparePostalCodeData } from '../../features/siret/siretSlice.js';

const SearchCommune = () => {
  const dispatch = useDispatch();

  const [codePostal, setCodePostal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [canauxAccessibles, setCanauxAccessibles] = useState([]);
  const [communeDisponible, setCommuneDisponible] = useState(null);

  // État qui gère l'ouverture/fermeture de la section
  const [isOpen, setIsOpen] = useState(false);

  // Quelques styles de base pour rendre la section lisible
  const containerStyle = {
    backgroundColor: '#f2f2f2',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '450px',
    margin: '20px auto',
    textAlign: 'center',
  };

  const titleStyle = {
    marginBottom: '20px',
    color: '#2f4f4f',
  };

  const inputStyle = {
  
    padding: '10px',
    fontSize: '1rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    outline: 'none',
  };

  const paragraphStyle = {
    color: '#2f4f4f',
    marginTop: '1rem',
  };

  const hrStyle = {
    border: 'none',
    borderBottom: '1px solid #ccc',
    margin: '8px 0',
  };

  const buttonToggleStyle = {
    backgroundColor: '#2f4f4f',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    padding: '10px 20px',
    cursor: 'pointer',
    margin: '0 auto',
    display: 'block', // pour centrer le bouton
  };

  const handleToggleSection = () => {
    setIsOpen((prev) => !prev);
  };

  const handleChangeCodePostal = (e) => {
    const newCodePostal = e.target.value;
    setCodePostal(newCodePostal);

    // Vérification code postal 5 chiffres
    const postalCodePattern = /^\d{5}$/;
    if (!postalCodePattern.test(newCodePostal)) {
      setCanauxAccessibles([]);
      setCommuneDisponible(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Appel Redux pour récupérer les canaux
    dispatch(ComparePostalCodeData(newCodePostal))
      .then((response) => {
        // On ne récupère que les canaux où canal.canalReachable = true
        const result = response.payload?.filter(
          (canal) => canal.canalReachable
        );
        setCanauxAccessibles(result || []);

        // Si on a au moins un canal accessible, la commune est disponible
        if (result && result.length > 0) {
          setCommuneDisponible(true);
        } else {
          setCommuneDisponible(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la recherche de la commune :", error);
        setCanauxAccessibles([]);
        setCommuneDisponible(false);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ margin: '40px 0' }}>
      <button style={buttonToggleStyle} onClick={handleToggleSection}>
        {isOpen ? 'X' : 'Appuyez pour chercher votre commune  →'}
      </button>

      {isOpen && (
        <div style={containerStyle}>
          <h2 style={titleStyle}>Rechercher une commune</h2>

          <input
            type="text"
            value={codePostal}
            onChange={handleChangeCodePostal}
            placeholder="Entrez un code postal (5 chiffres)"
            onKeyPress={(e) => {
              // Empêche le formulaire de se soumettre si l’utilisateur appuie sur 'Enter'
              if (e.key === 'Enter') e.preventDefault();
            }}
            style={inputStyle}
          />

          {isLoading ? (
            <div style={{ marginTop: '1rem' }}>
              <div className="loaderPayment"></div>
            </div>
          ) : codePostal.length === 5 ? (
            communeDisponible === true ? (
              <div style={{ marginTop: '1rem' }}>
                <p style={paragraphStyle}>Une commune est disponible.</p>
                {canauxAccessibles.map((canal, index) => (
                  <div
                    key={index}
                    style={{ ...paragraphStyle, textAlign: 'left' }}
                  >
                    <strong>Nom du canal :</strong> {canal.name}
                    <br />
                    <strong>Ville :</strong> {canal.city}
                    <hr style={hrStyle} />
                  </div>
                ))}
              </div>
            ) : communeDisponible === false ? (
              <p style={paragraphStyle}>Aucune commune disponible.</p>
            ) : (
              // État intermédiaire quand le code postal est valide mais pas encore de réponse
              <p style={paragraphStyle}>Veuillez patienter...</p>
            )
          ) : (
            // Si le code postal n’est pas encore valide ou vide
            <p style={paragraphStyle}>
              Entrez un code postal pour vérifier la disponibilité d’une commune.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchCommune;
